<template>
	<div v-if="!isLoading">
		<div class="row mb-3">
			<div class="col-12 col-lg-9 text-left">
				<h4>INFORMATION</h4>
				<div class="row" v-if="event_info != null">
					<div
						class="col-4"
						v-for="post in event_info.slice(0, 3)"
						:key="'event_post' + post.id"
					>
						<a :href="post.link">
							<img
								:src="
									post._embedded['wp:featuredmedia'][0]['media_details'][
										'sizes'
									]['medium']['source_url']
								"
								v-if="
									post._embedded['wp:featuredmedia'] != undefined &&
									post._embedded['wp:featuredmedia'][0] != undefined &&
									post._embedded['wp:featuredmedia'][0]['media_details'] !=
										undefined
								"
							/>
							<img src="img/common/no_image.jpg" v-else />
						</a>
						<a :href="post.link" class="text-left">
							<h6 class="pt-2 mb-0">{{ post.title.rendered }}</h6>
						</a>
						<p class="text-right">
							<span class="badge badge-dark">{{
								getStringFromDate(post.date)
							}}</span>
						</p>
					</div>
				</div>
				<p class="text-right">
					<a
						class="btn btn-outline-secondary btn-sm"
						href="https://blog.assist-kyoto.co.jp/category/event_infomation/"
						target="_blank"
					>
						<i class="fas fa-arrow-circle-right"></i> もっと見る
					</a>
				</p>
			</div>
			<div class="col-12 col-lg-3 text-left">
				<h4>REPORT</h4>
				<div class="row" v-if="event_report != null">
					<div
						class="col-12"
						v-for="post in event_report.slice(0, 1)"
						:key="'event_post' + post.id"
					>
						<a :href="post.link">
							<img
								:src="
									post._embedded['wp:featuredmedia'][0]['media_details'][
										'sizes'
									]['medium']['source_url']
								"
								v-if="
									post._embedded['wp:featuredmedia'] != undefined &&
									post._embedded['wp:featuredmedia'][0] != undefined &&
									post._embedded['wp:featuredmedia'][0]['media_details'] !=
										undefined
								"
							/>
							<img src="img/common/no_image.jpg" v-else />
						</a>
						<a :href="post.link" class="text-left">
							<h6 class="pt-2 mb-0">{{ post.title.rendered }}</h6>
						</a>
						<p class="text-right">
							<span class="badge badge-dark">{{
								getStringFromDate(post.date)
							}}</span>
						</p>
					</div>
				</div>
				<p class="text-right">
					<a
						class="btn btn-outline-secondary btn-sm"
						href="https://blog.assist-kyoto.co.jp/category/event_report/"
						target="_blank"
					>
						<i class="fas fa-arrow-circle-right"></i> もっと見る
					</a>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
export default {
	name: "Event",
	data() {
		return {
			isLoading: true,
			event_info: null,
			event_report: null,
		};
	},
	mounted: function () {
		this.getList();
	},
	methods: {
		getList: async function () {
			this.isLoading = true;
			let endpoint = "";
			//const endpoint = "https://laptorr.com/blogs/news.atom";
			endpoint = "https://blog.assist-kyoto.co.jp/wp-json/wp/v2/posts?_embed";
			// イベント情報
			axios
				.get(endpoint, {
					params: {
						categories: [1],
					},
				})
				.then((res) => {
					//console.log("res:" + JSON.stringify(res.data));
					this.event_info = res.data;
					// 最新3件を逆順に変更
					let arr = [];
					this.event_info
						.slice(0, 3)
						.forEach((element) => arr.unshift(element));
					this.event_info = arr;

					setTimeout(() => {
						this.isLoading = false;
					}, 500);
				});
			// イベントレポート
			axios
				.get(endpoint, {
					params: {
						categories: [4],
					},
				})
				.then((res) => {
					//console.log("res:" + JSON.stringify(res.data));
					this.event_report = res.data;
					setTimeout(() => {
						this.isLoading = false;
					}, 500);
				});
		},
		getStringFromDate: function (date) {
			let d = new Date(date);
			return (
				d.getFullYear() +
				"." +
				("0" + (d.getMonth() + 1)).slice(-2) +
				"." +
				("0" + d.getDate()).slice(-2)
			);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h4 {
	font-size: 1.2rem;
	font-weight: 600;
	color: #1976d2;
	margin-bottom: 1.6rem;
	padding: 0 0 0 0.6rem;
	border-left: 6px solid #152535;
	border-bottom: 2px solid #152535;
	span {
		font-size: 0.8rem;
		font-weight: normal;
		padding-left: 0.4rem;
	}
}
h6 {
	font-weight: 500;
	font-size: 0.9rem;
}
</style>
