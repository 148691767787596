var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoading)?_c('div',[_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-12 col-lg-9 text-left"},[_c('h4',[_vm._v("INFORMATION")]),(_vm.event_info != null)?_c('div',{staticClass:"row"},_vm._l((_vm.event_info.slice(0, 3)),function(post){return _c('div',{key:'event_post' + post.id,staticClass:"col-4"},[_c('a',{attrs:{"href":post.link}},[(
								post._embedded['wp:featuredmedia'] != undefined &&
								post._embedded['wp:featuredmedia'][0] != undefined &&
								post._embedded['wp:featuredmedia'][0]['media_details'] !=
									undefined
							)?_c('img',{attrs:{"src":post._embedded['wp:featuredmedia'][0]['media_details'][
									'sizes'
								]['medium']['source_url']}}):_c('img',{attrs:{"src":"img/common/no_image.jpg"}})]),_c('a',{staticClass:"text-left",attrs:{"href":post.link}},[_c('h6',{staticClass:"pt-2 mb-0"},[_vm._v(_vm._s(post.title.rendered))])]),_c('p',{staticClass:"text-right"},[_c('span',{staticClass:"badge badge-dark"},[_vm._v(_vm._s(_vm.getStringFromDate(post.date)))])])])}),0):_vm._e(),_vm._m(0)]),_c('div',{staticClass:"col-12 col-lg-3 text-left"},[_c('h4',[_vm._v("REPORT")]),(_vm.event_report != null)?_c('div',{staticClass:"row"},_vm._l((_vm.event_report.slice(0, 1)),function(post){return _c('div',{key:'event_post' + post.id,staticClass:"col-12"},[_c('a',{attrs:{"href":post.link}},[(
								post._embedded['wp:featuredmedia'] != undefined &&
								post._embedded['wp:featuredmedia'][0] != undefined &&
								post._embedded['wp:featuredmedia'][0]['media_details'] !=
									undefined
							)?_c('img',{attrs:{"src":post._embedded['wp:featuredmedia'][0]['media_details'][
									'sizes'
								]['medium']['source_url']}}):_c('img',{attrs:{"src":"img/common/no_image.jpg"}})]),_c('a',{staticClass:"text-left",attrs:{"href":post.link}},[_c('h6',{staticClass:"pt-2 mb-0"},[_vm._v(_vm._s(post.title.rendered))])]),_c('p',{staticClass:"text-right"},[_c('span',{staticClass:"badge badge-dark"},[_vm._v(_vm._s(_vm.getStringFromDate(post.date)))])])])}),0):_vm._e(),_vm._m(1)])])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"text-right"},[_c('a',{staticClass:"btn btn-outline-secondary btn-sm",attrs:{"href":"https://blog.assist-kyoto.co.jp/category/event_infomation/","target":"_blank"}},[_c('i',{staticClass:"fas fa-arrow-circle-right"}),_vm._v(" もっと見る ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"text-right"},[_c('a',{staticClass:"btn btn-outline-secondary btn-sm",attrs:{"href":"https://blog.assist-kyoto.co.jp/category/event_report/","target":"_blank"}},[_c('i',{staticClass:"fas fa-arrow-circle-right"}),_vm._v(" もっと見る ")])])}]

export { render, staticRenderFns }