<template>
	<OwlCarousel
		:items="items"
		:margin="30"
		:loog="true"
		:nav="false"
		:dots="false"
		:autoplay="true"
		:autoplayTimeout="5000"
		:smartSpeed="1000"
		:autoplayHoverPause="true"
		v-if="data != null"
		class="carousel"
	>
		<template v-for="(item, key) in data.contents">
			<div class="mb-3" :key="key">
				<a :href="'/tuned/' + item.id">
					<img :src="item.main_image.url" v-if="item.main_image != undefined" />
					<img src="/img/common/no_image.jpg" class="img-fluid" v-else />

					<h3 class="name mt-2">
						<i class="fas fa-angle-double-right mr-2"></i>{{ item.name
						}}<span class="badge badge-dark ml-2">{{
							getStringFromDate(item.publishedAt)
						}}</span>
					</h3>
				</a>
			</div>
		</template>
	</OwlCarousel>
</template>

<script>
import OwlCarousel from "vue-owl-carousel2";
import axios from "axios";

export default {
	name: "Carousel",
	props: {
		items: Number,
		endpoint: String,
	},
	components: {
		OwlCarousel,
	},
	data() {
		return {
			isLoading: false,
			data: null,
		};
	},
	mounted: function () {
		this.getData();
	},
	methods: {
		getData: function () {
			this.isLoading = true;
			// microcms
			// https://document.microcms.io/content-api/get-list-contents
			axios
				.get(this.endpoint, {
					headers: {
						"X-MICROCMS-API-KEY": "Zcnvkvp6KxnPWkf2WF9Uu7inXkkClTsv1YYr",
					},
				})
				.then((res) => {
					//console.log("res:" + JSON.stringify(res.data));
					this.data = res.data;
					// simulate AJAX
					setTimeout(() => {
						this.isLoading = false;
					}, 500);
				});
		},
		getStringFromDate: function (date) {
			let d = new Date(date);
			return (
				d.getFullYear() +
				"." +
				("0" + (d.getMonth() + 1)).slice(-2) +
				"." +
				("0" + d.getDate()).slice(-2)
			);
		},
	},
};
</script>

<style scoped lang="scss">
/* Tuned BMW  */
.carousel {
	img {
		margin-bottom: 1rem;
	}
	h3 {
		font-size: 1.2rem;
		padding: 0 0 0.6rem 0.2rem;
		border-bottom: 3px solid #000;
		border-left: none;
		&.name {
			color: #343a40;
			font-weight: 500;
			position: relative;
		}
		&.cs {
			color: #aaa;
		}
	}
	.badge {
		font-size: 0.8rem;
	}
}
</style>
