<template>
	<div v-if="!isLoading && list != null">
		<div v-for="post in list.slice(0, 5)" :key="'new_item_post' + post.id">
			<div class="posts row mb-3">
				<div class="col-4">
					<a :href="post.link">
						<img
							:src="
								post._embedded['wp:featuredmedia'][0]['media_details']['sizes'][
									'medium'
								]['source_url']
							"
							v-if="
								post._embedded['wp:featuredmedia'][0]['media_details'] !=
								undefined
							"
						/>
						<img src="img/common/no_image.jpg" v-else />
					</a>
				</div>
				<div class="col-8 text-left">
					<a :href="post.link" class="post-title">
						<h6>
							<a :href="post.link">{{ post.title.rendered | unescape }}</a>
						</h6>
						<span class="badge badge-dark">{{
							getStringFromDate(post.date)
						}}</span>
					</a>
					<div class="post-meta">
						<p class="post-text">
							<a :href="post.link">{{
								post.excerpt.rendered | nohtml | shortText
							}}</a>
						</p>
					</div>
				</div>
			</div>
		</div>
		<p class="text-right">
			<a
				class="btn btn-outline-secondary btn-sm"
				href="https://item.assist-kyoto.co.jp/category/recommends/"
				target="_blank"
			>
				<i class="fas fa-arrow-circle-right"></i> もっと見る
			</a>
		</p>
	</div>
</template>

<script>
import axios from "axios";
export default {
	name: "RecommendItems",
	data() {
		return {
			isLoading: true,
			list: null,
		};
	},
	mounted: function () {
		this.getList();
	},
	methods: {
		getList: async function () {
			this.isLoading = true;
			const params = {
				// おすすめ
				categories: [12],
			};
			const endpoint =
				"https://item.assist-kyoto.co.jp/wp-json/wp/v2/posts?_embed";
			axios
				.get(endpoint, {
					params: params,
				})
				.then((res) => {
					//console.log("res:" + JSON.stringify(res.data));
					this.list = res.data;
					setTimeout(() => {
						this.isLoading = false;
					}, 500);
				});
		},
		getStringFromDate: function (date) {
			let d = new Date(date);
			return (
				d.getFullYear() +
				"." +
				("0" + (d.getMonth() + 1)).slice(-2) +
				"." +
				("0" + d.getDate()).slice(-2)
			);
		},
	},
	filters: {
		nohtml: function (str) {
			return str.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, "");
		},
		shortText: function (str) {
			const MAX_LENGTH = 50; //文字数上限
			if (str.length > MAX_LENGTH) {
				str = str.substr(0, MAX_LENGTH) + "...";
			}
			return str;
		},
		unescape: function (str) {
			str = str.replace("&#038;", "&");
			return str;
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h6 a {
	font-weight: 500;
}
p.post-text {
	line-height: 1.2;
}
</style>
