<template>
	<div class="home">
		<!-- ##### Welcome Slide Area Start ##### -->
		<div class="welcome-slide-area mb-4">
			<div class="container">
				<div class="row">
					<div class="col-12">
						<div class="welcome-slides">
							<div class="single-welcome-slide">
								<div class="row no-gutters">
									<div class="col-12 col-lg-12">
										<div class="welcome-post">
											<img src="img/top/main.jpg" alt />
											<!--<div class="post-content">
												<h1><span>ASSIST KYOTO</span></h1>
												<p>
													When you use ASSIST, please enjoy sightseeing and a
													dish of historical Kyoto. The skill of BMW and the
													skill of the Kyoto dish have the thing which I shared.
													In ASSIST, this in the same way; aim when share it.
												</p>
											</div>-->
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- ##### Welcome Slide Area End ##### -->

		<!-- // Tuned BMW -->
		<div class="tuned_bmw area">
			<div class="container text-center">
				<div class="row">
					<div class="col-12">
						<h2>TUNED CAR</h2>

						<div class="assist_cars">
							<div class="hero-slides text-left d-block d-sm-none">
								<Carousel :items="1" :endpoint="endpoint_assist"> </Carousel>
							</div>
							<div class="hero-slides text-left d-none d-sm-block d-lg-none">
								<Carousel :items="2" :endpoint="endpoint_assist"> </Carousel>
							</div>
							<div class="hero-slides text-left d-none d-lg-block">
								<Carousel :items="3" :endpoint="endpoint_assist"> </Carousel>
							</div>
						</div>

						<div class="user_cars mt-4">
							<div class="hero-slides text-left d-block d-sm-none">
								<Carousel :items="1" :endpoint="endpoint_user"> </Carousel>
							</div>
							<div class="hero-slides text-left d-none d-sm-block d-lg-none">
								<Carousel :items="2" :endpoint="endpoint_user"> </Carousel>
							</div>
							<div class="hero-slides text-left d-none d-lg-block">
								<Carousel :items="3" :endpoint="endpoint_user"> </Carousel>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- Tuned BMW // -->

		<!-- // Event -->
		<div class="event area">
			<div class="container text-center">
				<h2>PLAY WITH BMW</h2>
				<Event></Event>
			</div>
		</div>
		<!-- Event // -->

		<div class="items area">
			<div class="container text-center">
				<div class="row">
					<!-- // NewItems -->
					<div class="news col-12 col-lg-6 text-center">
						<h2>UPDATE</h2>
						<NewItems></NewItems>
					</div>
					<!-- NewItems // -->

					<!-- // Recommend -->
					<div class="event col-12 col-lg-6 text-center">
						<h2>RECOMMENDS</h2>
						<Recommend></Recommend>
					</div>
					<!-- Recommend // -->
				</div>
			</div>
		</div>

		<!-- // Social -->

		<div class="social area">
			<div class="container text-center">
				<h2>SOCIAL</h2>
				<div class="row">
					<!-- // Mori Blog -->
					<div class="col-12 col-lg-4 text-center mb-4">
						<div class="card text-left">
							<div class="card-header">
								<img src="img/top/ameblo.gif" class="mr-2" /><a
									href="http://ameblo.jp/assist-motorsport/"
									target="_blank"
									>ASSIST Mori の M Technology</a
								>
							</div>
							<ul class="list-group list-group-flush" v-if="ameblo != null">
								<li
									class="list-group-item"
									v-for="post in ameblo.slice(0, 5)"
									:key="'post_' + post.url[0]"
								>
									<span class="badge badge-dark">{{ post.date }}</span
									><br />
									<a :href="post.url[0]" target="_blank"
										><i class="fa fa-rss-square"></i> {{ post.title[0] }}</a
									>
								</li>
							</ul>
						</div>
					</div>
					<!-- Mori Blog // -->
					<!-- // ASSIST FB Page -->
					<div class="col-12 col-lg-4 text-center mb-4">
						<div
							class="fb-page"
							data-href="https://www.facebook.com/ASSIST.KYOTO"
							data-tabs="timeline"
							data-width=""
							data-height="380"
							data-small-header="false"
							data-adapt-container-width="true"
							data-hide-cover="false"
							data-show-facepile="true"
						>
							<blockquote
								cite="https://www.facebook.com/ASSIST.KYOTO"
								class="fb-xfbml-parse-ignore"
							>
								<a href="https://www.facebook.com/ASSIST.KYOTO">ASSIST</a>
							</blockquote>
						</div>
					</div>
					<!-- ASSIST FB Page // -->
					<!-- // LAPTORR FB Page -->
					<div class="col-12 col-lg-4 text-center mb-4">
						<div
							class="fb-page"
							data-href="https://www.facebook.com/LAPTORR"
							data-tabs="timeline"
							data-width=""
							data-height="380"
							data-small-header="false"
							data-adapt-container-width="true"
							data-hide-cover="false"
							data-show-facepile="true"
						>
							<blockquote
								cite="https://www.facebook.com/LAPTORR"
								class="fb-xfbml-parse-ignore"
							>
								<a href="https://www.facebook.com/LAPTORR">LAPTORR</a>
							</blockquote>
						</div>
					</div>
					<!-- LAPTORR FB Page // -->
				</div>
			</div>
		</div>
		<!-- Social // -->
	</div>
</template>

<script>
import Carousel from "@/components/Carousel.vue";
import NewItems from "@/components/NewItems.vue";
import Recommend from "@/components/Recommend.vue";
import Event from "@/components/Event.vue";
import axios from "axios";

export default {
	name: "Home",
	components: {
		Carousel,
		NewItems,
		Recommend,
		Event,
	},
	data() {
		return {
			isLoading: false,
			ameblo: null,
			endpoint_assist:
				"https://assist-concept-cars.microcms.io/api/v1/cars?offset=0&orders=-publishedAt&filters=democar[equals]true",
			endpoint_user:
				"https://assist-concept-cars.microcms.io/api/v1/cars?offset=0&orders=-publishedAt&filters=democar[equals]false",
		};
	},
	mounted: function () {
		this.getRSS();
		// facebook page
		if (!document.getElementById("facebook-jssdk")) {
			(function (d, s, id) {
				var js,
					fjs = d.getElementsByTagName(s)[0];
				if (d.getElementById(id)) return;
				js = d.createElement(s);
				js.id = id;
				js.src =
					"https://connect.facebook.net/ja_JP/sdk.js#xfbml=1&version=v3.2";
				fjs.parentNode.insertBefore(js, fjs);
			})(document, "script", "facebook-jssdk");
		}
	},
	methods: {
		getRSS: function () {
			this.isLoading = true;
			// 森さんブログ
			let endpoint = "https://blog.assist-kyoto.co.jp/ameba.php";
			axios.get(endpoint, {}).then((res) => {
				//console.log("rss:" + JSON.stringify(res.data));
				this.ameblo = res.data;
				// simulate AJAX
				setTimeout(() => {
					this.isLoading = false;
				}, 500);
			});
		},
		getStringFromDate: function (date) {
			let d = new Date(date);
			return (
				d.getFullYear() +
				"." +
				("0" + (d.getMonth() + 1)).slice(-2) +
				"." +
				("0" + d.getDate()).slice(-2)
			);
		},
	},
};
</script>

<style scoped lang="scss">
.post-content {
	h1 {
		color: #fff;
		span {
			padding: 1rem;
			background-color: #000;
		}
	}
	p {
		padding: 0.6rem;
		background-color: #000;
	}
}
h2 {
	text-align: left;
	font-size: 1.4rem;
	padding: 0 1rem;
	color: #b9e3f9;
	background-image: -webkit-gradient(
		linear,
		right top,
		left top,
		from(#1976d2),
		to(#19acd2)
	);
	background-image: -webkit-linear-gradient(left, #b9e3f9, #1976d2 100%);
	background-image: linear-gradient(to left, #b9e3f9 0%, #1976d2 100%);
}
/* Tuned BMW  */
h3 {
	font-size: 1rem;
	padding: 1rem 0 0.6rem 7rem;
	border-bottom: 3px solid #000;
	border-left: none;
	&.name {
		color: #343a40;
		font-weight: 500;
		position: relative;
		span {
			font-size: 3rem;
			font-weight: bold;
			line-height: 1;
			position: absolute;
			bottom: -0.2rem;
			left: 0;
		}
	}
	&.cs {
		color: #aaa;
	}
}

.area {
	padding: 4rem 0;
}
.tuned_bmw,
.items {
	background-color: #f3f5f8;
}
</style>
